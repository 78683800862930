import { Icon, IconProps as ChakraIconProps } from '@chakra-ui/react';
import { forwardRef, JSXElementConstructor, SVGProps } from 'react';
import {
  HiDotsHorizontal,
  HiDotsVertical,
  HiOutlineArrowNarrowDown,
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
  HiOutlineArrowNarrowUp,
  HiOutlineChevronDown,
  HiOutlineChevronLeft,
  HiOutlineChevronRight,
  HiOutlineChevronUp,
  HiOutlineExclamationCircle,
  HiOutlineX,
} from 'react-icons/hi';

/*
  Warning: only some of the svgs here have been updated to allow setting stroke and fill separately.
  The old svgs only allow setting the stroke color, but in them it is declared with "fill" (due to the svg faulty construction)
  The old legacy svgs have the isLegacyIcon boolean at true, and should be replaced in the future for more flexibility
*/
import { ReactComponent as SvgAcademicCap } from './assets/Academic Cap.svg';
import { ReactComponent as SvgAdjustmentsHorizontal } from './assets/Adjustments Horizontal.svg';
import { ReactComponent as SvgApi } from './assets/Api.svg';
import { ReactComponent as SvgArchiveBoxArrowDown } from './assets/Archive Box Arrow Down.svg';
import { ReactComponent as SvgArchive } from './assets/Archive.svg';
import { ReactComponent as SvgArrowTwoWays } from './assets/Arrow 2 Ways.svg';
import { ReactComponent as SvgArrowDownOnSquareStack } from './assets/Arrow Down On Square Stack.svg';
import { ReactComponent as SvgArrowDownOnSquare } from './assets/Arrow Down On Square.svg';
import { ReactComponent as SvgArrowDownRight } from './assets/Arrow Down Right.svg';
import { ReactComponent as SvgArrowDown } from './assets/Arrow Down.svg';
import { ReactComponent as SvgArrowPath } from './assets/Arrow Path.svg';
import { ReactComponent as SvgArrowRightRegular } from './assets/Arrow Right Regular.svg';
import { ReactComponent as SvgArrowRight } from './assets/Arrow Right.svg';
import { ReactComponent as SvgArrowShare } from './assets/Arrow Share.svg';
import { ReactComponent as SvgArrowTrendingDown } from './assets/Arrow Trending Down.svg';
import { ReactComponent as SvgArrowTrendingUp } from './assets/Arrow Trending Up.svg';
import { ReactComponent as SvgArrowUpRight } from './assets/Arrow Up Right.svg';
import { ReactComponent as SvgArrowsPointingIn } from './assets/Arrows Pointing In.svg';
import { ReactComponent as SvgArrowsPointingOut } from './assets/Arrows Pointing Out.svg';
import { ReactComponent as SvgArrowsRightLeft } from './assets/Arrows Right Left.svg';
import { ReactComponent as SvgArrowsUpDown } from './assets/Arrows Up Down.svg';
import { ReactComponent as SvgBank } from './assets/Bank.svg';
import { ReactComponent as SvgBanknotes } from './assets/Banknotes.svg';
import { ReactComponent as SvgBars3BottomLeft } from './assets/Bars 3 Bottom Left.svg';
import { ReactComponent as SvgBars3BottomRight } from './assets/Bars 3 Bottom Right.svg';
import { ReactComponent as SvgBars3CenterLeft } from './assets/Bars 3 Center Left.svg';
import { ReactComponent as SvgBars3 } from './assets/Bars 3.svg';
import { ReactComponent as SvgBarsArrowDown } from './assets/Bars Arrow Down.svg';
import { ReactComponent as SvgBarsArrowUp } from './assets/Bars Arrow Up.svg';
import { ReactComponent as SvgBattery0 } from './assets/Battery 0.svg';
import { ReactComponent as SvgBattery50 } from './assets/Battery 50.svg';
import { ReactComponent as SvgBattery100 } from './assets/Battery 100.svg';
import { ReactComponent as SvgBeaker } from './assets/Beaker.svg';
import { ReactComponent as SvgBehance } from './assets/Behance.svg';
import { ReactComponent as SvgBellAlert } from './assets/Bell Alert.svg';
import { ReactComponent as SvgBellSnooze } from './assets/Bell Snooze.svg';
import { ReactComponent as SvgBell } from './assets/Bell.svg';
import { ReactComponent as SvgBinary } from './assets/Binary.svg';
import { ReactComponent as SvgBlank } from './assets/Blank 24px.svg';
import { ReactComponent as SvgBolt } from './assets/Bolt.svg';
import { ReactComponent as SvgBookOpen } from './assets/Book Open.svg';
import { ReactComponent as SvgBookmarkSquare } from './assets/Bookmark Square.svg';
import { ReactComponent as SvgBookmark } from './assets/Bookmark.svg';
import { ReactComponent as SvgBriefcase } from './assets/Briefcase.svg';
import { ReactComponent as SvgBroadcast } from './assets/Broadcast.svg';
import { ReactComponent as SvgBuildingLibrary } from './assets/Building Library.svg';
import { ReactComponent as SvgBuildingOffice2 } from './assets/Building Office 2.svg';
import { ReactComponent as SvgBuildingOffice } from './assets/Building Office.svg';
import { ReactComponent as SvgBuildingStorefront } from './assets/Building Storefront.svg';
import { ReactComponent as SvgCake } from './assets/Cake.svg';
import { ReactComponent as SvgCalculator } from './assets/Calculator.svg';
import { ReactComponent as SvgCalendar } from './assets/Calendar 24px.svg';
import { ReactComponent as SvgCalendarDays } from './assets/Calendar Days.svg';
import { ReactComponent as SvgCalendar2 } from './assets/Calendar.svg';
import { ReactComponent as SvgCamera } from './assets/Camera.svg';
import { ReactComponent as SvgCart } from './assets/Cart.svg';
import { ReactComponent as SvgChartBarSquare } from './assets/Chart Bar Square.svg';
import { ReactComponent as SvgChartBar } from './assets/Chart Bar.svg';
import { ReactComponent as SvgChartDown } from './assets/Chart Down.svg';
import { ReactComponent as SvgChartPie } from './assets/Chart Pie.svg';
import { ReactComponent as SvgChat } from './assets/chat 24px.svg';
import { ReactComponent as SvgChatBubbleBottomCenterText } from './assets/Chat Bubble Bottom Center Text.svg';
import { ReactComponent as SvgChatBubbleLeftEllipsis } from './assets/Chat Bubble Left Ellipsis.svg';
import { ReactComponent as SvgChatBubbleLeftRight } from './assets/Chat Bubble Left Right.svg';
import { ReactComponent as SvgCheck } from './assets/check 24px.svg';
import { ReactComponent as SvgCheckBadge } from './assets/Check Badge.svg';
import { ReactComponent as SvgCheckCircle } from './assets/Check Circle.svg';
import { ReactComponent as SvgCheck2 } from './assets/Check.svg';
import { ReactComponent as SvgCircleStack } from './assets/Circle Stack.svg';
import { ReactComponent as SvgCircle } from './assets/Circle.svg';
import { ReactComponent as SvgClipFile } from './assets/Clip File.svg';
import { ReactComponent as SvgClipboardDocumentCheck } from './assets/Clipboard Document Check.svg';
import { ReactComponent as SvgClipboardDocumentList } from './assets/Clipboard Document List.svg';
import { ReactComponent as SvgClipboardDocument } from './assets/Clipboard Document.svg';
import { ReactComponent as SvgClipboard } from './assets/Clipboard.svg';
import { ReactComponent as SvgClock } from './assets/Clock.svg';
import { ReactComponent as SvgCloudArrowDown } from './assets/Cloud Arrow Down.svg';
import { ReactComponent as SvgCloudArrowUp } from './assets/Cloud Arrow Up.svg';
import { ReactComponent as SvgCloud } from './assets/Cloud.svg';
import { ReactComponent as SvgCodeBracketSquare } from './assets/Code Bracket Square.svg';
import { ReactComponent as SvgCodeBracket } from './assets/Code Bracket.svg';
import { ReactComponent as SvgCog } from './assets/Cog.svg';
import { ReactComponent as SvgCoins } from './assets/Coins.svg';
import { ReactComponent as SvgCollectiveLogo } from './assets/Collective logo.svg';
import { ReactComponent as SvgCollectiveLongLogo } from './assets/Collective Long Logo.svg';
import { ReactComponent as SvgCollectiveShortLogo } from './assets/Collective Short Logo.svg';
import { ReactComponent as SvgCommandLine } from './assets/Command Line.svg';
import { ReactComponent as SvgCompany } from './assets/Company.svg';
import { ReactComponent as SvgCompass } from './assets/Compass.svg';
import { ReactComponent as SvgComputerDesktop } from './assets/Computer Desktop.svg';
import { ReactComponent as SvgCpuChip } from './assets/Cpu Chip.svg';
import { ReactComponent as SvgCreditCard } from './assets/Credit Card 24px.svg';
import { ReactComponent as SvgCreditCard2 } from './assets/Credit Card.svg';
import { ReactComponent as SvgCubeTransparent } from './assets/Cube Transparent.svg';
import { ReactComponent as SvgCube } from './assets/Cube.svg';
import { ReactComponent as SvgCurrencyDollar } from './assets/Currency Dollar.svg';
import { ReactComponent as SvgCurrencyEuro } from './assets/Currency Euro.svg';
import { ReactComponent as SvgCurrencyPound } from './assets/Currency Pound.svg';
import { ReactComponent as SvgCursorArrowRipple } from './assets/Cursor Arrow Ripple.svg';
import { ReactComponent as SvgDevicePhoneMobile } from './assets/Device Phone Mobile.svg';
import { ReactComponent as SvgDeviceTablet } from './assets/Device Tablet.svg';
import { ReactComponent as SvgDocumentChartBar } from './assets/Document Chart Bar.svg';
import { ReactComponent as SvgDocumentCheck } from './assets/Document Check.svg';
import { ReactComponent as SvgDragDrop } from './assets/Drag drop.svg';
import { ReactComponent as SvgDribbble } from './assets/Dribbble.svg';
import { ReactComponent as SvgDuplicate } from './assets/Duplicate.svg';
import { ReactComponent as SvgEditSquare } from './assets/Edit Square.svg';
import { ReactComponent as SvgEdit } from './assets/Edit.svg';
import { ReactComponent as SvgEditorAlignedCenter } from './assets/Editor Aligned Center.svg';
import { ReactComponent as SvgEditorAlignedDistributed } from './assets/Editor Aligned Distributed.svg';
import { ReactComponent as SvgEditorAlignedLeft } from './assets/Editor Aligned Left.svg';
import { ReactComponent as SvgEditorAlignedRight } from './assets/Editor Aligned Right.svg';
import { ReactComponent as SvgEditorBold } from './assets/Editor Bold.svg';
import { ReactComponent as SvgEditorBulletPoint } from './assets/Editor Bullet Point.svg';
import { ReactComponent as SvgEditorH1 } from './assets/Editor H1.svg';
import { ReactComponent as SvgEditorH2 } from './assets/Editor H2.svg';
import { ReactComponent as SvgEditorH3 } from './assets/Editor H3.svg';
import { ReactComponent as SvgEditorItalic } from './assets/Editor Italic.svg';
import { ReactComponent as SvgEditorNumbers } from './assets/Editor Numbers.svg';
import { ReactComponent as SvgEditorStyle } from './assets/Editor Style.svg';
import { ReactComponent as SvgEditorUnderline } from './assets/Editor Underline.svg';
import { ReactComponent as SvgError } from './assets/error 24px.svg';
import { ReactComponent as SvgExternalLink } from './assets/External Link.svg';
import { ReactComponent as SvgEyeDisplayed } from './assets/Eye Displayed 24px.svg';
import { ReactComponent as SvgEyeHidden } from './assets/Eye Hidden 24px.svg';
import { ReactComponent as SvgEye } from './assets/Eye.svg';
import { ReactComponent as SvgFileAdd } from './assets/File Add.svg';
import { ReactComponent as SvgFile } from './assets/File.svg';
import { ReactComponent as SvgFilm } from './assets/Film.svg';
import { ReactComponent as SvgFingerPrint } from './assets/Finger Print.svg';
import { ReactComponent as SvgFire } from './assets/Fire.svg';
import { ReactComponent as SvgFiver } from './assets/Fiver.svg';
import { ReactComponent as SvgFlag } from './assets/Flag.svg';
import { ReactComponent as SvgFloppyDisk } from './assets/Floppy Disk.svg';
import { ReactComponent as SvgFolderArrowDown } from './assets/Folder Arrow Down.svg';
import { ReactComponent as SvgFolderOpen } from './assets/Folder Open.svg';
import { ReactComponent as SvgFolder } from './assets/Folder.svg';
import { ReactComponent as SvgForbidden } from './assets/Forbidden.svg';
import { ReactComponent as SvgFunnel } from './assets/Funnel.svg';
import { ReactComponent as SvgGif } from './assets/Gif.svg';
import { ReactComponent as SvgGiftTop } from './assets/Gift Top.svg';
import { ReactComponent as SvgGift } from './assets/Gift.svg';
import { ReactComponent as SvgGift2 } from './assets/Gift2.svg';
import { ReactComponent as SvgGithub } from './assets/Github.svg';
import { ReactComponent as SvgGlobeAlt } from './assets/Globe Alt.svg';
import { ReactComponent as SvgGlobeAmericas } from './assets/Globe Americas.svg';
import { ReactComponent as SvgGlobeAsiaAustralia } from './assets/Globe Asia Australia.svg';
import { ReactComponent as SvgGoogle } from './assets/Google 24px.svg';
import { ReactComponent as SvgGoogleWhite } from './assets/Google-White.svg';
import { ReactComponent as SvgHandRaised } from './assets/Hand Raised.svg';
import { ReactComponent as SvgHandThumbUp } from './assets/Hand Thumb Up.svg';
import { ReactComponent as SvgHand } from './assets/Hand.svg';
import { ReactComponent as SvgHappy } from './assets/Happy.svg';
import { ReactComponent as SvgHashtagRotated } from './assets/Hashtag Rotated.svg';
import { ReactComponent as SvgHashtag } from './assets/Hashtag.svg';
import { ReactComponent as SvgHeartFilled } from './assets/Heart Filled.svg';
import { ReactComponent as SvgHeart } from './assets/Heart.svg';
import { ReactComponent as SvgHomeModern } from './assets/Home Modern.svg';
import { ReactComponent as SvgHome } from './assets/Home.svg';
import { ReactComponent as SvgHome2 } from './assets/Home2.svg';
import { ReactComponent as SvgIdentification } from './assets/Identification.svg';
import { ReactComponent as SvgImport } from './assets/Import.svg';
import { ReactComponent as SvgInboxComing } from './assets/Inbox Coming 24px.svg';
import { ReactComponent as SvgInboxStack } from './assets/Inbox Stack.svg';
import { ReactComponent as SvgInbox } from './assets/Inbox.svg';
import { ReactComponent as SvgInbox2 } from './assets/Inbox2.svg';
import { ReactComponent as SvgInfo } from './assets/info 24px.svg';
import { ReactComponent as SvgInstagram } from './assets/Instagram.svg';
import { ReactComponent as SvgInvoice } from './assets/Invoice.svg';
import { ReactComponent as SvgKey } from './assets/Key.svg';
import { ReactComponent as SvgLifebuoy } from './assets/Lifebuoy.svg';
import { ReactComponent as SvgLightBulb } from './assets/Light Bulb.svg';
import { ReactComponent as SvgLink } from './assets/Link.svg';
import { ReactComponent as SvgLink2 } from './assets/Link2.svg';
import { ReactComponent as SvgLinkedIn } from './assets/LinkedIn.svg';
import { ReactComponent as SvgListBullet } from './assets/List Bullet.svg';
import { ReactComponent as SvgLockClosed } from './assets/Lock Closed.svg';
import { ReactComponent as SvgLockOpen } from './assets/Lock Open.svg';
import { ReactComponent as SvgMagicWand } from './assets/Magic Wand.svg';
import { ReactComponent as SvgMagnifyingGlass } from './assets/Magnifying Glass.svg';
import { ReactComponent as SvgMail } from './assets/Mail.svg';
import { ReactComponent as SvgMalt } from './assets/Malt.svg';
import { ReactComponent as SvgMapPin } from './assets/Map Pin.svg';
import { ReactComponent as SvgMap } from './assets/Map.svg';
import { ReactComponent as SvgMegaphone } from './assets/Megaphone.svg';
import { ReactComponent as SvgMessage } from './assets/Message 24px.svg';
import { ReactComponent as SvgMilestone } from './assets/Milestone.svg';
import { ReactComponent as SvgMinus } from './assets/Minus.svg';
import { ReactComponent as SvgMoney } from './assets/Money 24px.svg';
import { ReactComponent as SvgMusicalNote } from './assets/Musical Note.svg';
import { ReactComponent as SvgNewspaper } from './assets/Newspaper.svg';
import { ReactComponent as SvgOff } from './assets/Off.svg';
import { ReactComponent as SvgOpportunities } from './assets/Opportunities.svg';
import { ReactComponent as SvgOpportunitiesLeft } from './assets/OpportunitiesLeft.svg';
import { ReactComponent as SvgOut } from './assets/Out.svg';
import { ReactComponent as SvgPaintBrush } from './assets/Paint Brush.svg';
import { ReactComponent as SvgPeopleNetwork } from './assets/PeopleNetwork.svg';
import { ReactComponent as SvgPhone } from './assets/Phone.svg';
import { ReactComponent as SvgPhoto } from './assets/Photo.svg';
import { ReactComponent as SvgPicture } from './assets/Picture.svg';
import { ReactComponent as SvgPinterest } from './assets/Pinterest.svg';
import { ReactComponent as SvgPlayCircle } from './assets/Play Circle.svg';
import { ReactComponent as SvgPlay } from './assets/Play.svg';
import { ReactComponent as SvgPlusCircle } from './assets/Plus Circle.svg';
import { ReactComponent as SvgPlus } from './assets/Plus.svg';
import { ReactComponent as SvgPresentationChartBar } from './assets/Presentation Chart Bar.svg';
import { ReactComponent as SvgPresentationChartLine } from './assets/Presentation Chart Line.svg';
import { ReactComponent as SvgPresentation } from './assets/Presentation.svg';
import { ReactComponent as SvgProtectCollective } from './assets/Protect Collective.svg';
import { ReactComponent as SvgPuzzlePiece } from './assets/Puzzle Piece.svg';
import { ReactComponent as SvgQrCode } from './assets/Qr Code.svg';
import { ReactComponent as SvgQuestion } from './assets/Question.svg';
import { ReactComponent as SvgQueueList } from './assets/Queue List.svg';
import { ReactComponent as SvgQuickAction } from './assets/Quick Action.svg';
import { ReactComponent as SvgRadio } from './assets/Radio.svg';
import { ReactComponent as SvgReceiptPercent } from './assets/Receipt Percent.svg';
import { ReactComponent as SvgRefresh } from './assets/Refresh.svg';
import { ReactComponent as SvgResume } from './assets/Resume.svg';
import { ReactComponent as SvgRocket } from './assets/Rocket.svg';
import { ReactComponent as SvgRss } from './assets/Rss.svg';
import { ReactComponent as SvgScale } from './assets/Scale.svg';
import { ReactComponent as SvgSearch } from './assets/search 24px.svg';
import { ReactComponent as SvgSelector } from './assets/Selector.svg';
import { ReactComponent as SvgSendingVertical } from './assets/Sending Vertical.svg';
import { ReactComponent as SvgSending } from './assets/Sending.svg';
import { ReactComponent as SvgServerStack } from './assets/Server Stack.svg';
import { ReactComponent as SvgServer } from './assets/Server.svg';
import { ReactComponent as SvgSettings } from './assets/settings 24px.svg';
import { ReactComponent as SvgShare } from './assets/Share.svg';
import { ReactComponent as SvgShieldCheckFull } from './assets/Shield Check Full.svg';
import { ReactComponent as SvgShieldCheck } from './assets/Shield Check.svg';
import { ReactComponent as SvgShieldExclamation } from './assets/Shield Exclamation.svg';
import { ReactComponent as SvgShieldLock } from './assets/Shield Lock.svg';
import { ReactComponent as SvgShop } from './assets/Shop 24px.svg';
import { ReactComponent as SvgShoppingBag } from './assets/Shopping Bag.svg';
import { ReactComponent as SvgShoppingCart } from './assets/Shopping Cart.svg';
import { ReactComponent as SvgSignOut } from './assets/Sign Out 24px.svg';
import { ReactComponent as SvgSignal } from './assets/Signal.svg';
import { ReactComponent as SvgSimpleKey } from './assets/Simple Key.svg';
import { ReactComponent as SvgSitemap } from './assets/Sitemap.svg';
import { ReactComponent as SvgSmallStar } from './assets/Small Star.svg';
import { ReactComponent as SvgSparkles } from './assets/Sparkles.svg';
import { ReactComponent as SvgSpeakerWave } from './assets/Speaker Wave.svg';
import { ReactComponent as SvgSpeechBubble } from './assets/Speech Bubble.svg';
import { ReactComponent as SvgSplitArrowsTwoWays } from './assets/Split arrows 2 ways.svg';
import { ReactComponent as SvgSplitArrows } from './assets/SplitArrows.svg';
import { ReactComponent as SvgSquareBracket } from './assets/SquareBracket.svg';
import { ReactComponent as SvgSquares2x2 } from './assets/Squares 2x2.svg';
import { ReactComponent as SvgStackoverflow } from './assets/Stackoverflow.svg';
import { ReactComponent as SvgStarFilled } from './assets/Star Filled.svg';
import { ReactComponent as SvgStar } from './assets/Star.svg';
import { ReactComponent as SvgSun } from './assets/Sun.svg';
import { ReactComponent as SvgSwatch } from './assets/Swatch.svg';
import { ReactComponent as SvgTableCells } from './assets/Table Cells.svg';
import { ReactComponent as SvgTag } from './assets/Tag.svg';
import { ReactComponent as SvgTikTok } from './assets/TikTok.svg';
import { ReactComponent as SvgTime13 } from './assets/Time 1-3.svg';
import { ReactComponent as SvgTime23 } from './assets/Time 2-3.svg';
import { ReactComponent as SvgTime33 } from './assets/Time 3-3.svg';
import { ReactComponent as SvgTime } from './assets/Time 24px.svg';
import { ReactComponent as SvgToastError } from './assets/Toast Error.svg';
import { ReactComponent as SvgToastInfo } from './assets/Toast Info.svg';
import { ReactComponent as SvgToastSuccess } from './assets/Toast Success.svg';
import { ReactComponent as SvgTrash } from './assets/Trash.svg';
import { ReactComponent as SvgTruck } from './assets/Truck.svg';
import { ReactComponent as SvgTwitch } from './assets/Twitch.svg';
import { ReactComponent as SvgUpwork } from './assets/Upwork.svg';
import { ReactComponent as SvgUserAdd } from './assets/User Add.svg';
import { ReactComponent as SvgUserSquare } from './assets/User Square.svg';
import { ReactComponent as SvgUser } from './assets/User.svg';
import { ReactComponent as SvgUsers } from './assets/Users.svg';
import { ReactComponent as SvgVideoCamera } from './assets/Video Camera.svg';
import { ReactComponent as SvgViewColumns } from './assets/View Columns.svg';
import { ReactComponent as SvgVimeo } from './assets/Vimeo.svg';
import { ReactComponent as SvgWallet } from './assets/Wallet.svg';
import { ReactComponent as SvgWebsite } from './assets/Website.svg';
import { ReactComponent as SvgWork } from './assets/Work.svg';
import { ReactComponent as SvgWrenchScrewdriver } from './assets/Wrench Screwdriver.svg';
import { ReactComponent as SvgWrench } from './assets/Wrench.svg';
import { ReactComponent as SvgX } from './assets/X.svg';
import { ReactComponent as SvgYouTube } from './assets/YouTube.svg';

/**
 * ICONS FROM react-icons/hi
 */
export const IconChevronUp = withChakraIcon(HiOutlineChevronUp);
export const IconChevronDown = withChakraIcon(HiOutlineChevronDown);
export const IconChevronLeft = withChakraIcon(HiOutlineChevronLeft);
export const IconChevronRight = withChakraIcon(HiOutlineChevronRight);
export const IconDotsVertical = withChakraIcon(HiDotsVertical, {
  isLegacyIcon: true,
});
export const IconDotsHorizontal = withChakraIcon(HiDotsHorizontal, {
  isLegacyIcon: true,
});
export const IconX = withChakraIcon(HiOutlineX);
export const IconArrowNarrowLeft = withChakraIcon(HiOutlineArrowNarrowLeft);
export const IconArrowNarrowRight = withChakraIcon(HiOutlineArrowNarrowRight);
export const IconArrowNarrowUp = withChakraIcon(HiOutlineArrowNarrowUp);

export const IconArrowNarrowDown = withChakraIcon(HiOutlineArrowNarrowDown);
export const IconExclamationCircle = withChakraIcon(HiOutlineExclamationCircle);

/**
 * CUSTOM ICONS
 */
export const IconApi = withChakraIcon(SvgApi, { isLegacyIcon: true });
export const IconArchive = withChakraIcon(SvgArchive);
export const IconBinary = withChakraIcon(SvgBinary, { isLegacyIcon: true });
export const IconBlank = withChakraIcon(SvgBlank, { isLegacyIcon: true });
export const IconBroadcast = withChakraIcon(SvgBroadcast, {
  isLegacyIcon: true,
});
export const IconCalendar = withChakraIcon(SvgCalendar, { isLegacyIcon: true });
export const IconChartDown = withChakraIcon(SvgChartDown, {
  isLegacyIcon: true,
});
export const IconChat = withChakraIcon(SvgChat, { isLegacyIcon: true });
export const IconCheck = withChakraIcon(SvgCheck, { isLegacyIcon: true });
export const IconCollectiveLogo = withChakraIcon(SvgCollectiveLogo, {
  isLegacyIcon: true,
});
export const IconCompany = withChakraIcon(SvgCompany);
export const IconCompass = withChakraIcon(SvgCompass);
export const IconSpeechBubble = withChakraIcon(SvgSpeechBubble);
export const IconCreditCard = withChakraIcon(SvgCreditCard, {
  isLegacyIcon: true,
});
export const IconDragDrop = withChakraIcon(SvgDragDrop);
export const IconDuplicate = withChakraIcon(SvgDuplicate, {
  isLegacyIcon: true,
});
export const IconEdit = withChakraIcon(SvgEdit, { isLegacyIcon: true });
export const IconEditSquare = withChakraIcon(SvgEditSquare, {
  isLegacyIcon: true,
});
export const IconEditorAlignedCenter = withChakraIcon(SvgEditorAlignedCenter);
export const IconEditorAlignedDistributed = withChakraIcon(
  SvgEditorAlignedDistributed
);
export const IconEditorAlignedLeft = withChakraIcon(SvgEditorAlignedLeft);
export const IconEditorAlignedRight = withChakraIcon(SvgEditorAlignedRight);
export const IconEditorBold = withChakraIcon(SvgEditorBold, {
  isLegacyIcon: true,
});
export const IconEditorBulletPoint = withChakraIcon(SvgEditorBulletPoint);
export const IconEditorH1 = withChakraIcon(SvgEditorH1, { isLegacyIcon: true });
export const IconEditorH2 = withChakraIcon(SvgEditorH2, { isLegacyIcon: true });
export const IconEditorH3 = withChakraIcon(SvgEditorH3, { isLegacyIcon: true });
export const IconEditorItalic = withChakraIcon(SvgEditorItalic);
export const IconEditorNumbers = withChakraIcon(SvgEditorNumbers);
export const IconEditorStyle = withChakraIcon(SvgEditorStyle);
export const IconEditorUnderline = withChakraIcon(SvgEditorUnderline);
export const IconError = withChakraIcon(SvgError, { isLegacyIcon: true });
export const IconEyeDisplayed = withChakraIcon(SvgEyeDisplayed, {
  isLegacyIcon: true,
});
export const IconExternalLink = withChakraIcon(SvgExternalLink);
export const IconEyeHidden = withChakraIcon(SvgEyeHidden, {
  isLegacyIcon: true,
});
export const IconFile = withChakraIcon(SvgFile, { isLegacyIcon: true });
export const IconFileAdd = withChakraIcon(SvgFileAdd);
export const IconFloppyDisk = withChakraIcon(SvgFloppyDisk);
export const IconFolder = withChakraIcon(SvgFolder);
export const IconForbidden = withChakraIcon(SvgForbidden);
export const IconGift = withChakraIcon(SvgGift);
export const IconHome = withChakraIcon(SvgHome);
export const IconInbox = withChakraIcon(SvgInbox);
export const IconInboxComing = withChakraIcon(SvgInboxComing, {
  isLegacyIcon: true,
});
export const IconInfo = withChakraIcon(SvgInfo, { isLegacyIcon: true });
export const IconLink = withChakraIcon(SvgLink);
export const IconMail = withChakraIcon(SvgMail);
export const IconMessage = withChakraIcon(SvgMessage, { isLegacyIcon: true });
export const IconMilestone = withChakraIcon(SvgMilestone, {
  isLegacyIcon: true,
});
export const IconMoney = withChakraIcon(SvgMoney, { isLegacyIcon: true });
export const IconOut = withChakraIcon(SvgOut);
export const IconOpportunities = withChakraIcon(SvgOpportunities);
export const IconOpportunitiesLeft = withChakraIcon(SvgOpportunitiesLeft, {
  isLegacyIcon: true,
});
export const IconOff = withChakraIcon(SvgOff);
export const IconPeopleNetwork = withChakraIcon(SvgPeopleNetwork, {
  isLegacyIcon: true,
});
export const IconPhone = withChakraIcon(SvgPhone, { isLegacyIcon: true });
export const IconPicture = withChakraIcon(SvgPicture);
export const IconPlus = withChakraIcon(SvgPlus, { isLegacyIcon: true });
export const IconMinus = withChakraIcon(SvgMinus, { isLegacyIcon: true });
export const IconPresentation = withChakraIcon(SvgPresentation, {
  isLegacyIcon: true,
});
export const IconRefresh = withChakraIcon(SvgRefresh);
export const IconSearch = withChakraIcon(SvgSearch, { isLegacyIcon: true });
export const IconSelector = withChakraIcon(SvgSelector);
export const IconSending = withChakraIcon(SvgSending, { isLegacyIcon: true });
export const IconSendingVertical = withChakraIcon(SvgSendingVertical, {
  isLegacyIcon: true,
});
export const IconSettings = withChakraIcon(SvgSettings, { isLegacyIcon: true });
export const IconShop = withChakraIcon(SvgShop, { isLegacyIcon: true });
export const IconSignOut = withChakraIcon(SvgSignOut, { isLegacyIcon: true });
export const IconSquareBracket = withChakraIcon(SvgSquareBracket, {
  isLegacyIcon: true,
});
export const IconToastError = withChakraIcon(SvgToastError, {
  isLegacyIcon: true,
  fixedColor: true,
});
export const IconToastSuccess = withChakraIcon(SvgToastSuccess, {
  isLegacyIcon: true,
  fixedColor: true,
});
export const IconToastInfo = withChakraIcon(SvgToastInfo, {
  isLegacyIcon: true,
  fixedColor: true,
});
export const IconTime = withChakraIcon(SvgTime, { isLegacyIcon: true });
export const IconGoogle = withChakraIcon(SvgGoogle, { fixedColor: true });
export const IconTime13 = withChakraIcon(SvgTime13, { isLegacyIcon: true });
export const IconTime23 = withChakraIcon(SvgTime23, { isLegacyIcon: true });
export const IconTime33 = withChakraIcon(SvgTime33, { isLegacyIcon: true });
export const IconTrash = withChakraIcon(SvgTrash);
export const IconUserSquare = withChakraIcon(SvgUserSquare);
export const IconUser = withChakraIcon(SvgUser);
export const IconUserAdd = withChakraIcon(SvgUserAdd);
export const IconUsers = withChakraIcon(SvgUsers);
export const IconInvoice = withChakraIcon(SvgInvoice);
export const IconImport = withChakraIcon(SvgImport);
export const IconWallet = withChakraIcon(SvgWallet);
export const IconWebsite = withChakraIcon(SvgWebsite);
export const IconQuestion = withChakraIcon(SvgQuestion);
export const IconCart = withChakraIcon(SvgCart);
export const IconHappy = withChakraIcon(SvgHappy);
export const IconCollectiveShortLogo = withChakraIcon(SvgCollectiveShortLogo, {
  isLegacyIcon: true,
});
export const IconCollectiveLongLogo = withChakraIcon(SvgCollectiveLongLogo, {
  isLegacyIcon: true,
});
export const IconAcademicCap = withChakraIcon(SvgAcademicCap, {
  isLegacyIcon: true,
});
export const IconAdjustmentsHorizontal = withChakraIcon(
  SvgAdjustmentsHorizontal
);
export const IconArchiveBoxArrowDown = withChakraIcon(SvgArchiveBoxArrowDown, {
  isLegacyIcon: true,
});
export const IconArrowDown = withChakraIcon(SvgArrowDown);
export const IconArrowDownOnSquareStack = withChakraIcon(
  SvgArrowDownOnSquareStack,
  { isLegacyIcon: true }
);
export const IconArrowDownOnSquare = withChakraIcon(SvgArrowDownOnSquare, {
  isLegacyIcon: true,
});
export const IconArrowDownRight = withChakraIcon(SvgArrowDownRight, {
  isLegacyIcon: true,
});
export const IconArrowPath = withChakraIcon(SvgArrowPath);
export const IconArrowTrendingDown = withChakraIcon(SvgArrowTrendingDown, {
  isLegacyIcon: true,
});
export const IconArrowTrendingUp = withChakraIcon(SvgArrowTrendingUp, {
  isLegacyIcon: true,
});
export const IconArrowUpRight = withChakraIcon(SvgArrowUpRight, {
  isLegacyIcon: true,
});
export const IconArrowsPointingIn = withChakraIcon(SvgArrowsPointingIn, {
  isLegacyIcon: true,
});
export const IconArrowsPointingOut = withChakraIcon(SvgArrowsPointingOut, {
  isLegacyIcon: true,
});
export const IconArrowsRightLeft = withChakraIcon(SvgArrowsRightLeft, {
  isLegacyIcon: true,
});
export const IconArrowsUpDown = withChakraIcon(SvgArrowsUpDown, {
  isLegacyIcon: true,
});
export const IconBank = withChakraIcon(SvgBank);
export const IconBanknotes = withChakraIcon(SvgBanknotes, {
  isLegacyIcon: true,
});
export const IconBars3BottomLeft = withChakraIcon(SvgBars3BottomLeft, {
  isLegacyIcon: true,
});
export const IconBars3BottomRight = withChakraIcon(SvgBars3BottomRight, {
  isLegacyIcon: true,
});
export const IconBars3CenterLeft = withChakraIcon(SvgBars3CenterLeft, {
  isLegacyIcon: true,
});
export const IconBars3 = withChakraIcon(SvgBars3, { isLegacyIcon: true });
export const IconBarsArrowDown = withChakraIcon(SvgBarsArrowDown, {
  isLegacyIcon: true,
});
export const IconBarsArrowUp = withChakraIcon(SvgBarsArrowUp, {
  isLegacyIcon: true,
});
export const IconBattery0 = withChakraIcon(SvgBattery0, { isLegacyIcon: true });
export const IconBattery50 = withChakraIcon(SvgBattery50, {
  isLegacyIcon: true,
});
export const IconBattery100 = withChakraIcon(SvgBattery100, {
  isLegacyIcon: true,
});
export const IconBeaker = withChakraIcon(SvgBeaker, { isLegacyIcon: true });
export const IconBellAlert = withChakraIcon(SvgBellAlert);
export const IconBellSnooze = withChakraIcon(SvgBellSnooze);
export const IconBell = withChakraIcon(SvgBell);
export const IconBolt = withChakraIcon(SvgBolt);
export const IconBookOpen = withChakraIcon(SvgBookOpen, { isLegacyIcon: true });
export const IconBookmarkSquare = withChakraIcon(SvgBookmarkSquare);
export const IconBookmark = withChakraIcon(SvgBookmark, { isLegacyIcon: true });
export const IconBriefcase = withChakraIcon(SvgBriefcase);
export const IconBuildingLibrary = withChakraIcon(SvgBuildingLibrary, {
  isLegacyIcon: true,
});
export const IconBuildingOffice2 = withChakraIcon(SvgBuildingOffice2, {
  isLegacyIcon: true,
});
export const IconBuildingOffice = withChakraIcon(SvgBuildingOffice, {
  isLegacyIcon: true,
});
export const IconBuildingStorefront = withChakraIcon(SvgBuildingStorefront, {
  isLegacyIcon: true,
});
export const IconCake = withChakraIcon(SvgCake, { isLegacyIcon: true });
export const IconCalendar2 = withChakraIcon(SvgCalendar2);
export const IconCalculator = withChakraIcon(SvgCalculator);
export const IconCalendarDays = withChakraIcon(SvgCalendarDays);
export const IconCamera = withChakraIcon(SvgCamera);
export const IconChartBarSquare = withChakraIcon(SvgChartBarSquare);
export const IconChartBar = withChakraIcon(SvgChartBar);
export const IconChartPie = withChakraIcon(SvgChartPie);
export const IconChatBubbleBottomCenterText = withChakraIcon(
  SvgChatBubbleBottomCenterText
);
export const IconChatBubbleLeftEllipsis = withChakraIcon(
  SvgChatBubbleLeftEllipsis
);
export const IconChatBubbleLeftRight = withChakraIcon(SvgChatBubbleLeftRight);
export const IconCheckBadge = withChakraIcon(SvgCheckBadge);
export const IconCheck2 = withChakraIcon(SvgCheck2);
export const IconCheckCircle = withChakraIcon(SvgCheckCircle);
export const IconCircle = withChakraIcon(SvgCircle);
export const IconCircleStack = withChakraIcon(SvgCircleStack);
export const IconClipboardDocumentCheck = withChakraIcon(
  SvgClipboardDocumentCheck
);
export const IconClipboardDocumentList = withChakraIcon(
  SvgClipboardDocumentList,
  {
    isLegacyIcon: true,
  }
);
export const IconClipboardDocument = withChakraIcon(SvgClipboardDocument, {
  isLegacyIcon: true,
});
export const IconClipboard = withChakraIcon(SvgClipboard);
export const IconClipFile = withChakraIcon(SvgClipFile);
export const IconClock = withChakraIcon(SvgClock, { isLegacyIcon: true });
export const IconCloudArrowDown = withChakraIcon(SvgCloudArrowDown, {
  isLegacyIcon: true,
});
export const IconCloudArrowUp = withChakraIcon(SvgCloudArrowUp, {
  isLegacyIcon: true,
});
export const IconCloud = withChakraIcon(SvgCloud);
export const IconCodeBracketSquare = withChakraIcon(SvgCodeBracketSquare, {
  isLegacyIcon: true,
});
export const IconCodeBracket = withChakraIcon(SvgCodeBracket, {
  isLegacyIcon: true,
});
export const IconCog = withChakraIcon(SvgCog, { isLegacyIcon: true });
export const IconCoins = withChakraIcon(SvgCoins);
export const IconCommandLine = withChakraIcon(SvgCommandLine);
export const IconComputerDesktop = withChakraIcon(SvgComputerDesktop, {
  isLegacyIcon: true,
});
export const IconCpuChip = withChakraIcon(SvgCpuChip);
export const IconCreditCard2 = withChakraIcon(SvgCreditCard2, {
  isLegacyIcon: true,
});
export const IconCubeTransparent = withChakraIcon(SvgCubeTransparent, {
  isLegacyIcon: true,
});
export const IconCube = withChakraIcon(SvgCube, { isLegacyIcon: true });
export const IconCurrencyDollar = withChakraIcon(SvgCurrencyDollar, {
  isLegacyIcon: true,
});
export const IconCurrencyEuro = withChakraIcon(SvgCurrencyEuro, {
  isLegacyIcon: true,
});
export const IconCurrencyPound = withChakraIcon(SvgCurrencyPound, {
  isLegacyIcon: true,
});
export const IconCursorArrowRipple = withChakraIcon(SvgCursorArrowRipple, {
  isLegacyIcon: true,
});
export const IconDevicePhoneMobile = withChakraIcon(SvgDevicePhoneMobile, {
  isLegacyIcon: true,
});
export const IconDeviceTablet = withChakraIcon(SvgDeviceTablet, {
  isLegacyIcon: true,
});
export const IconDocumentChartBar = withChakraIcon(SvgDocumentChartBar, {
  isLegacyIcon: true,
});
export const IconDocumentCheck = withChakraIcon(SvgDocumentCheck);
export const IconEye = withChakraIcon(SvgEye);
export const IconFilm = withChakraIcon(SvgFilm);
export const IconFingerPrint = withChakraIcon(SvgFingerPrint, {
  isLegacyIcon: true,
});
export const IconFire = withChakraIcon(SvgFire);
export const IconFlag = withChakraIcon(SvgFlag, { isLegacyIcon: true });
export const IconFolderArrowDown = withChakraIcon(SvgFolderArrowDown, {
  isLegacyIcon: true,
});
export const IconFolderOpen = withChakraIcon(SvgFolderOpen, {
  isLegacyIcon: true,
});
export const IconFunnel = withChakraIcon(SvgFunnel, { isLegacyIcon: true });
export const IconGif = withChakraIcon(SvgGif, { isLegacyIcon: true });
export const IconGiftTop = withChakraIcon(SvgGiftTop, { isLegacyIcon: true });
export const IconGift2 = withChakraIcon(SvgGift2, { isLegacyIcon: true });
export const IconGlobeAlt = withChakraIcon(SvgGlobeAlt);
export const IconGlobeAmericas = withChakraIcon(SvgGlobeAmericas, {
  isLegacyIcon: true,
});
export const IconGlobeAsiaAustralia = withChakraIcon(SvgGlobeAsiaAustralia, {
  isLegacyIcon: true,
});
export const IconHand = withChakraIcon(SvgHand);
export const IconHandRaised = withChakraIcon(SvgHandRaised);
export const IconHandThumbUp = withChakraIcon(SvgHandThumbUp);
export const IconHashtag = withChakraIcon(SvgHashtag, { isLegacyIcon: true });
export const IconHashtagRotated = withChakraIcon(SvgHashtagRotated, {
  isLegacyIcon: true,
});
export const IconHeart = withChakraIcon(SvgHeart);
export const IconHeartFilled = withChakraIcon(SvgHeartFilled, {
  isLegacyIcon: true,
});
export const IconHomeModern = withChakraIcon(SvgHomeModern, {
  isLegacyIcon: true,
});
export const IconHome2 = withChakraIcon(SvgHome2, { isLegacyIcon: true });
export const IconIdentification = withChakraIcon(SvgIdentification, {
  isLegacyIcon: true,
});
export const IconInboxStack = withChakraIcon(SvgInboxStack);
export const IconInbox2 = withChakraIcon(SvgInbox2, { isLegacyIcon: true });
export const IconKey = withChakraIcon(SvgKey, { isLegacyIcon: true });
export const IconLifebuoy = withChakraIcon(SvgLifebuoy, { isLegacyIcon: true });
export const IconLightBulb = withChakraIcon(SvgLightBulb, {
  isLegacyIcon: true,
});
export const IconLink2 = withChakraIcon(SvgLink2);
export const IconLinkedIn = withChakraIcon(SvgLinkedIn, { fixedColor: true });
export const IconGitHub = withChakraIcon(SvgGithub, { fixedColor: true });
export const IconStackOverflow = withChakraIcon(SvgStackoverflow, {
  fixedColor: true,
});
export const IconDribbble = withChakraIcon(SvgDribbble, { fixedColor: true });
export const IconBehance = withChakraIcon(SvgBehance, { fixedColor: true });
export const IconYouTube = withChakraIcon(SvgYouTube, { fixedColor: true });
export const IconInstagram = withChakraIcon(SvgInstagram, { fixedColor: true });
export const IconXCom = withChakraIcon(SvgX, { fixedColor: true });
export const IconMalt = withChakraIcon(SvgMalt, { fixedColor: true });
export const IconVimeo = withChakraIcon(SvgVimeo, { fixedColor: true });
export const IconTwitch = withChakraIcon(SvgTwitch, { fixedColor: true });
export const IconPinterest = withChakraIcon(SvgPinterest, { fixedColor: true });
export const IconFiver = withChakraIcon(SvgFiver, { fixedColor: true });
export const IconUpwork = withChakraIcon(SvgUpwork, { fixedColor: true });
export const IconTikTok = withChakraIcon(SvgTikTok, { fixedColor: true });
export const IconListBullet = withChakraIcon(SvgListBullet, {
  isLegacyIcon: true,
});
export const IconLockClosed = withChakraIcon(SvgLockClosed);
export const IconLockOpen = withChakraIcon(SvgLockOpen);
export const IconMagicWand = withChakraIcon(SvgMagicWand);
export const IconMagnifyingGlass = withChakraIcon(SvgMagnifyingGlass, {
  isLegacyIcon: true,
});
export const IconMapPin = withChakraIcon(SvgMapPin);
export const IconMap = withChakraIcon(SvgMap);
export const IconMegaphone = withChakraIcon(SvgMegaphone);
export const IconMusicalNote = withChakraIcon(SvgMusicalNote, {
  isLegacyIcon: true,
});
export const IconNewspaper = withChakraIcon(SvgNewspaper);
export const IconPaintBrush = withChakraIcon(SvgPaintBrush, {
  isLegacyIcon: true,
});
export const IconPhoto = withChakraIcon(SvgPhoto, { isLegacyIcon: true });
export const IconPlay = withChakraIcon(SvgPlay, {
  isLegacyIcon: true,
  fixedColor: true,
});
export const IconPlayCircle = withChakraIcon(SvgPlayCircle, {
  isLegacyIcon: true,
});
export const IconPresentationChartBar = withChakraIcon(SvgPresentationChartBar);
export const IconPresentationChartLine = withChakraIcon(
  SvgPresentationChartLine
);
export const IconPuzzlePiece = withChakraIcon(SvgPuzzlePiece, {
  isLegacyIcon: true,
});
export const IconProtectCollective = withChakraIcon(SvgProtectCollective);
export const IconQrCode = withChakraIcon(SvgQrCode);
export const IconQueueList = withChakraIcon(SvgQueueList, {
  isLegacyIcon: true,
});
export const IconQuickAction = withChakraIcon(SvgQuickAction);
export const IconRadio = withChakraIcon(SvgRadio, { isLegacyIcon: true });
export const IconReceiptPercent = withChakraIcon(SvgReceiptPercent, {
  isLegacyIcon: true,
});
export const IconRss = withChakraIcon(SvgRss);
export const IconRocket = withChakraIcon(SvgRocket);
export const IconScale = withChakraIcon(SvgScale, { isLegacyIcon: true });
export const IconServerStack = withChakraIcon(SvgServerStack, {
  isLegacyIcon: true,
});
export const IconServer = withChakraIcon(SvgServer, { isLegacyIcon: true });
export const IconShare = withChakraIcon(SvgShare);
export const IconShieldCheck = withChakraIcon(SvgShieldCheck);
export const IconShieldCheckFull = withChakraIcon(SvgShieldCheckFull, {
  isLegacyIcon: true,
});
export const IconShieldExclamation = withChakraIcon(SvgShieldExclamation);
export const IconShieldLock = withChakraIcon(SvgShieldLock);
export const IconShoppingBag = withChakraIcon(SvgShoppingBag, {
  isLegacyIcon: true,
});
export const IconShoppingCart = withChakraIcon(SvgShoppingCart, {
  isLegacyIcon: true,
});
export const IconSignal = withChakraIcon(SvgSignal, { isLegacyIcon: true });
export const IconSitemap = withChakraIcon(SvgSitemap);
export const IconSimpleKey = withChakraIcon(SvgSimpleKey);
export const IconSparkles = withChakraIcon(SvgSparkles, { isLegacyIcon: true });
export const IconSpeakerWave = withChakraIcon(SvgSpeakerWave, {
  isLegacyIcon: true,
});
export const IconSquares2x2 = withChakraIcon(SvgSquares2x2, {
  isLegacyIcon: true,
});
export const IconStar = withChakraIcon(SvgStar);

export const IconSmallStar = withChakraIcon(SvgSmallStar);
export const IconStarFilled = withChakraIcon(SvgStarFilled, {
  isLegacyIcon: true,
});
export const IconSun = withChakraIcon(SvgSun);
export const IconSwatch = withChakraIcon(SvgSwatch, { isLegacyIcon: true });
export const IconTableCells = withChakraIcon(SvgTableCells);
export const IconTag = withChakraIcon(SvgTag, { isLegacyIcon: true });
export const IconTruck = withChakraIcon(SvgTruck, { isLegacyIcon: true });
export const IconVideoCamera = withChakraIcon(SvgVideoCamera);
export const IconViewColumns = withChakraIcon(SvgViewColumns);
export const IconWrenchScrewdriver = withChakraIcon(SvgWrenchScrewdriver, {
  isLegacyIcon: true,
});
export const IconWrench = withChakraIcon(SvgWrench, {
  isLegacyIcon: true,
});
export const IconGoogleWhite = withChakraIcon(SvgGoogleWhite, {
  fixedColor: true,
});
export const IconSplitArrows = withChakraIcon(SvgSplitArrows);
export const IconArrowTwoWays = withChakraIcon(SvgArrowTwoWays);
export const IconSplitArrowsTwoWays = withChakraIcon(SvgSplitArrowsTwoWays);
export const IconArrowRight = withChakraIcon(SvgArrowRight);
export const IconArrowRightRegular = withChakraIcon(SvgArrowRightRegular);
export const IconArrowShare = withChakraIcon(SvgArrowShare);
export const IconPlusCircle = withChakraIcon(SvgPlusCircle);
export const IconWork = withChakraIcon(SvgWork);
export const IconResume = withChakraIcon(SvgResume);

export type IconSize = '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type IconProps = Omit<ChakraIconProps, 'css'> & {
  size?: IconSize;
  stroke?: string;
  fill?: string;
};

const sizeMap: Record<IconSize, number> = {
  '3xs': 8,
  '2xs': 12,
  xs: 16,
  sm: 20,
  md: 24,
  lg: 32,
  xl: 40,
};

function withChakraIcon(
  IconComponent: JSXElementConstructor<SVGProps<SVGSVGElement>>,
  options?: {
    isLegacyIcon?: boolean;
    fixedColor?: boolean;
  }
) {
  return forwardRef<SVGSVGElement, IconProps>(function WithChakraIcon(
    { size = 'md', color = 'currentColor', _hover, _groupHover, ...rest },
    ref
  ) {
    const coloredProperty = options?.isLegacyIcon ? 'fill' : 'stroke';
    const hoverColor = _hover?.color;
    const groupHoverColor = _groupHover?.color;
    const { sx = {} } = rest;

    return (
      <Icon
        className={options?.isLegacyIcon ? 'legacy-icon' : 'non-legacy-icon'}
        role="img"
        ref={ref}
        as={IconComponent}
        fontSize={sizeMap[size]}
        display="flex"
        transition=".1s ease-in-out color"
        _hover={_hover}
        _groupHover={_groupHover}
        {...rest}
        sx={
          options?.fixedColor
            ? { ...sx }
            : {
                '*': {
                  transition: `.1s ease-in-out ${coloredProperty}`,
                  [coloredProperty]: color,
                },
                _hover: {
                  '*': {
                    [coloredProperty]: hoverColor,
                  },
                },
                _groupHover: {
                  '*': {
                    [coloredProperty]: groupHoverColor,
                  },
                },
                ...sx,
              }
        }
      />
    );
  });
}

export { Icon };
